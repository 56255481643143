<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Auth from '@/services/Auth';
    import Statistics from '@/services/Statistics';

    export default {
        components: { Layout, PageHeader },
        page: {
            title: "Dashboard Settings",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Dashboard Settings",
                items: [
                    {
                        text: "Application",
                    },
                    {
                        text: "Dashboard Settings",
                        active: true,
                    },
                ],
                csrf_token: localStorage.getItem('csrf_token'),
                showLoader: false,
                showLoader1: false,
                loadingCharts: true,
                loadingChartsCommon : false ,
                error: null,
                auth2FATypes: [],
                auth2FATypeEditing: false,
                charts: [],
                charts1: [],
                modalOpen: false,
                tryingToSubmit: false,
                selectedCharts: [],
                unmatchedCharts: [] ,
                chartTypes : {} ,
                validationError : '' ,
                charts1Loaded : false ,
            };
        },
        async created() {
            await this.get2faTypes();
            await this.getDashboardCharts('data=tenant');
            await this.getDashboardCharts1('data=common');
        },
        methods: {
            async get2faTypes() {
                try {
                    await Auth.get2faTypes('type=all').then((response) => {
                        const authTypes = response.data.data;
                        if(authTypes){
                            this.auth2FATypes = authTypes.filter(authType => authType.type !== null);
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },
            async getDashboardCharts(data) {
                try {
                    this.loadingCharts = true;
                    const response = await Statistics.getDashboardCharts(data);
                    const charts = response.data.data.dashboard_charts;
                    this.charts = charts;
                } catch (error) {
                    this.error = error.response.data.error || "An error occurred";
                    this.failedmsg(this.error);
                }
                finally {
                    this.loadingCharts = false;
                }
            },
            async getDashboardCharts1(data) {
                try {
                    this.loadingChartsCommon = true;
                    const response = await Statistics.getDashboardCharts(data);
                    const charts1 = response.data.data.dashboard_charts;
                    this.charts1 = charts1;
                    this.charts1Loaded = true;
                } catch (error) {
                    this.error = error.response.data.error || "An error occurred";
                    this.failedmsg(this.error);
                }
            },
            async edit2FATypes(type, show) {
                const checkbox = document.getElementById(type);
                const newCheckedState = checkbox.checked;

                try {
                    this.auth2FATypeEditing = true
                    await Auth.edit2faTypes({
                        type: type,
                        show: show ? 1 : 0,
                        csrf_token: this.csrf_token
                    }).then((response) => {
                        if(response.data.data){
                        // ok
                        }
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                        checkbox.checked = !newCheckedState;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                    checkbox.checked = !newCheckedState;
                } finally {
                    this.auth2FATypeEditing = false
                }
            },
            openModal() {
                this.modalOpen = true;
                this.unmatchedCharts = this.charts1.filter(chart1 =>
                    !this.charts.some(chart => chart.id === chart1.id)
                );
            },
            closeModal() {
                this.modalOpen = false;
                this.selectedCharts = [];
                this.chartTypes = {};
                this.validationError = '' ;
            },
            async  submit() {

                this.validationError = '';
                for (let id of this.selectedCharts) {
                    if (!this.chartTypes[id]) {
                        this.validationError = 'Please select a chart type for each selected chart.';
                        return;
                    }
                }
                this.tryingToSubmit = true;
                const selectedChartObjects = this.selectedCharts.map(id => ({
                    ...this.unmatchedCharts.find(chart => chart.id === id),
                    chart_type: this.chartTypes[id] || 'line'
                }));

                try {

                    await Statistics.addDashboardCharts(selectedChartObjects).then((response) => {
                        this.successmsg('Dashboard chart added!');
                        this.getDashboardCharts('data=tenant');
                    }).catch(error => {
                        this.failedmsg('Failed to add dashboard chart.');
                    }).finally(() => {
                        this.closeModal()
                        this.tryingToSubmit = false;
                    })
                } catch (error) {
                    this.failedmsg('Failed to add dashboard chart.');
                }

            },
            async removeChart(id) {
                try {
                    const params = 'id=' + id;
                    await Statistics.deleteDashboardChart(params)
                        .then((response) => {
                            this.successmsg('Dashboard chart removed!');
                            this.getDashboardCharts('data=tenant');
                        })
                        .catch(error => {
                            this.failedmsg('Failed to remove dashboard chart.');
                        });
                } catch (error) {
                    this.failedmsg('Failed to remove dashboard chart.');
                }
            },
        },
        middleware: "authentication"
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4" v-if="!showLoader">
                            <div class="col-sm-12 col-md-12 mb-5">
                                <h5>Set 2FA Authentication Types: <b-spinner small v-if="auth2FATypeEditing" class="ms-1"></b-spinner></h5>
                                <b-form-checkbox-group>
                                    <div v-for="type in auth2FATypes" :key="type.type" class="form-check form-switch form-switch-lg">
                                        <input
                                                type="checkbox"
                                                class="form-check-input"
                                                :id="type.type"
                                                @change="edit2FATypes(type.type, $event.target.checked)"
                                                :checked="type.show"
                                        />
                                        <label class="form-check-label" :for="type.type">{{type.name}}</label>
                                    </div>
                                </b-form-checkbox-group>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-center justify-content-center" v-else>
                            <b-spinner large></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <button v-if="charts1Loaded && loadingChartsCommon" @click="openModal" class="btn btn-primary mb-3">Add</button>

                        <div class="row mt-4" v-if="!showLoader">
                            <div class="col-sm-12 col-md-12 mb-5">
                                <h5>Dashboard Charts <b-spinner small v-if="showLoader1" class="ms-1"></b-spinner></h5>
                                <b-modal v-model="modalOpen" title="Add Charts" hide-footer @hide="closeModal">
                                    <div v-if="unmatchedCharts.length === 0">
                                        <p>No unmatched charts found.</p>
                                    </div>
                                    <div v-else>
                                        <div class="row" v-for="chart in unmatchedCharts" :key="chart.id">
                                            <div class="col-6">
                                            <input type="checkbox" v-model="selectedCharts" :value="chart.id" style="margin-right: 10px;">
                                            <label>{{ chart.label_name }}</label>
                                            </div>
                                            <div class="col-6">
                                                <label style="margin-right: 10px;">Chart Type</label>
                                                <select v-model="chartTypes[chart.id]" class="form-select form-select-sm d-inline-block w-auto ml-2">
                                                    <option value="line">Line</option>
                                                    <option value="column">Column</option>
                                                    <option value="area">Area</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <p v-if="validationError" class="text-danger">{{ validationError }}</p>

                                        <button @click="submit" class="btn btn-primary" style="float: right ;" :disabled="tryingToSubmit">Submit
                                            <b-spinner v-show="tryingToSubmit" small></b-spinner>

                                        </button>
                                        <button @click="closeModal" class="btn btn-secondary" style="float: right ;margin-right: 10px">Cancel</button>
                                    </div>
                                </b-modal>
                                <div v-if="loadingCharts" class="text-center mt-4">
                                    <b-spinner label="Loading..." />
                                </div>
                                <table v-else class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Service</th>
                                        <th>Label</th>
                                        <th>Chart Type</th>
                                        <th>Data Type</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="chart in charts" :key="chart.id">
                                        <td>{{ chart.service }}</td>
                                        <td>{{ chart.label_name }}</td>
                                        <td>{{ chart.chart_type }}</td>
                                        <td>{{ chart.data_type }}</td>
                                        <td>
                                            <button @click="removeChart(chart.id)" class="btn btn-danger btn-sm">Remove</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-center justify-content-center" v-else>
                            <b-spinner large></b-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
